<template>
  <div class="content-handler">

    <!-- carousel -->
    <VueSlickCarousel ref="carousel" v-bind="settings">

      <!-- <div class="carousel-custom-item pekka">
        <div class="itemmi" v-for="itemmi in 20">
          <base-bg source="/img/bg_default.jpg" style="z-index: 2;"></base-bg>
          <base-title size="l" mT="s">Ref's title</base-title>
          <base-title size="s" mT="l">tldr;</base-title>
          <base-text :lorem="true" :loremLength="200"></base-text>
          <base-title size="s" mT="xl">description</base-title>
          <base-text :lorem="true"></base-text>
        </div>
      </div> -->

      <div class="carousel-custom-item front">
        <base-bg :source="`${$store.state.api.imageURL}${currentReference.id}/${currentReference.id}_pic0.png`"></base-bg>
        <!-- <base-flex center="y"> -->
        <!-- <div></div> -->

        <base-flex center="y" mT="s" mB="l">
          <base-title size="l" mT="s" mR="m">{{ this.currentReference.title }}</base-title>
          <base-link v-if="currentReference.link" :to="currentReference.link" mT="m">
            <base-icon>redirect</base-icon>
          </base-link>
        </base-flex>


        <!-- </base-flex> -->
        <!-- <base-title size="s" mT="l">tldr;</base-title> -->
        <base-text>{{ this.currentReference.tldr }}</base-text>
        <!-- <base-title size="s" mT="xl">description</base-title> -->
        <!-- <base-text :lorem="true"></base-text> -->
      </div>

      <!-- <div v-for="(item, index) in currentReference" :key="index" class="carousel-custom-item">
        <base-bg :source="item.bg" :full=true fit="contain"></base-bg>
        <base-title>{{ item.title }}</base-title>
      </div> -->
      <div v-for="(item, index) in currentReference.pics" :key="index" class="carousel-custom-item">
        <base-bg :source="`${$store.state.api.imageURL}${currentReference.id}/${currentReference.id}_${item}.png`" :full=true fit="contain"></base-bg>
        <!-- <base-title>{{ item.title }}</base-title> -->
      </div>
    </VueSlickCarousel>

    <!-- top bar -->
    <div class="top">
      <!-- <base-icon size="s" tooltip="description" @click="showDescription">comment</base-icon> -->
      <div class="close" @click="close">close</div>
    </div>

    <!-- togglers -->
    <base-flex class="toggle previous" center="xy" @click.native="goTo('prev')">
      <base-icon>left</base-icon>
    </base-flex>
    <base-flex class="toggle next" center="xy" @click.native="goTo('next')">
      <base-icon>right</base-icon>
    </base-flex>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css' // import style
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css' // optional style for arrows & dots

export default {
  name: 'contentHandler',

  components: { VueSlickCarousel },

  data() {
    return {
      settings: {
        accessibility: true,
        autoplay: false,
        dots: true,
        edgeFriction: 0.35,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        waitForAnimate: false
      }
    }
  },
  computed: {
    currentReference() {
      return this.$store.state.modals.contentOfCurrentReference
    }
  },

  methods: {
    close() {
      this.$store.dispatch('SET_STATE', { data: false, path: 'modals.showContentHandler' })
      this.$store.dispatch('SET_STATE', { data: false, path: 'app.ui.preventBodyScrolling' })
    },

    /* showDescription() {
      //let indexi = this.$refs.carousel.$el.slick.slickCurrentSlide()
      //let indexi = this.$refs.carousel.$el
      let indexi = this.$refs.carousel
      console.log(indexi);
      
      //alert('tästä tulee näkyviin referenssin selostus')
    }, */

    goTo(direction) {
      if (direction === 'prev') {
        this.$refs.carousel.prev()
      } else this.$refs.carousel.next()
    }
  }
}
</script>

<style lang="scss" scoped>
$carousel-color: $app-color--theme;
$carousel-color--bg: $app-color--main;
$padding-y--height: 2.4rem;

.content-handler {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparentize($carousel-color--bg, 0.05);

  @mixin full {
    position: relative;
    height: 100%;
  }

  // default classes of VueSlickCarousel
  &::v-deep .slick-slider {
    @include full;
    .slick-list {
      @include full;
      .slick-track {
        @include full;
        .slick-slide {
          position: relative;
          & > div {
            height: 100%;
            display: flex !important;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  &::v-deep .carousel-custom-item {
    position: relative;
    height: calc(100% - (2 * #{$padding-y--height}));
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    outline: 0;

    &.front {
      max-width: 800px !important;
      overflow-y: scroll;
      display: inline-block !important;
      //flex-direction: column;
      padding: 2rem;
      padding-top: 0;
      color: $carousel-color !important;
      & > div {
        width: 100%;
        //height: 100%;
      }
      .hero {
        width: 100%;
        height: 300px;
        //height: auto;
      }
      //align-items: initial;
      //& *:not(.base-bg) { flex-shrink: 0; }
      .base-bg { max-height: 50vmin; }
      .base-title { color: $carousel-color !important; }
      .base-text { color: $carousel-color !important; }
      .base-icon { color: white !important; }
    }

    &.pekka {
      //background: lightblue;
      
      overflow-y: auto;
      flex-direction: column;
      color: $carousel-color !important;
      & > * { color: $carousel-color !important; }
      .base-title { color: $carousel-color !important; }
      .base-text { color: $carousel-color !important; }
      .itemmi {
        padding: 5rem 2.2rem;
      }
    }
  }

  &::v-deep .slick-dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $padding-y--height;
    display: flex !important;
    align-items: center;
    justify-content: center;
    li {
      button:before {
        font-size: 0.7rem !important;
        color: $carousel-color;
        opacity: 0.2 !important;
        transition: opacity 0.5s ease;
      }
      &.slick-active button:before { opacity: 1 !important; }
    }
  }

  .top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $padding-y--height;
    display: flex;
    justify-content: flex-end;
    padding: 0 0.8rem;
    color: $carousel-color;
    .base-icon { @extend %clickable; }
    .close {
      position: relative;
      height: $padding-y--height;
      line-height: $padding-y--height;
      font-weight: 700;
      @extend %clickable;
    }
  }

  .toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 50px;
    display: flex;
    background: transparentize($carousel-color--bg, 0.05);
    color: $carousel-color;
    @extend %clickable;
    &.previous {
      left: 0;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;

    }
    &.next {
      right: 0;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
}
</style>
